footer {
  background:$secondary-color;
  color:$tertiary-color;
  padding-top:30px;
  .footer-items {
    display:flex;
    flex-direction: row;
    flex-wrap:wrap;
    justify-content: space-between;
    h4 {
      @include font_H4();
      color:white;
      margin-bottom:5px;
    }
    .locaties {
      @include font_P1();
      line-height:28px;
      color:white;
      display:flex;
      flex-direction: row;
      flex-wrap:wrap;
      .locatie {
        position:relative;
        padding-right:30px;
        ul {
          margin:28px 0 0 0;
          padding:0;
          list-style:none;
          li {
            a {
              @include font_P1();
              line-height:28px;
              color:white;
              &:hover,
              &:focus {
                text-decoration: none;
                color:$tertiary-color;
              }
            }
          }
        }
      }
      .locatie + .locatie {
        margin-top:2rem;
      }
    }
    .links {
      display:none;
      ul {
        margin:0;
        padding:0;
        list-style:none;
        li {
          a {
            @include font_P1();
            line-height:28px;
            color:white;
            &:hover,
            &:focus {
              text-decoration: none;
              color:$tertiary-color;
            }
          }
        }
      }
    }
  }
  .copyright {
    @include font_P1();
    font-size:13px;
    color:$tertiary-color;
    text-align:center;
    width:100%;
    margin:30px 0 0 0;
    padding:10px 0;
    border-top:1px solid rgba(185,191,187,.4);
  }
}
@media (min-width: 992px) {
  footer {
    padding-top:40px;
    .footer-items {
      .locaties {
        .locatie {
          padding-right:50px;
        }
        .locatie + .locatie {
          margin-top:0;
        }
      }
      .links {
        display:block;
      }
    }
    .copyright {
      margin:40px 0 0 0;
    }
  }
}
@media (min-width: 1200px) {
  footer {
    padding-top:50px;
    .footer-items {
      .locaties {
        .locatie {
          padding-right:65px;
        }
      }
    }
    .copyright {
      margin:50px 0 0 0;
    }
  }
}