.delivery-section {
  background:$tertiary-color;
  h2 {
    text-align:center;
    &:after {
      left:0;
      right:0;
      margin:0 auto;
    }
  }
  .delivery {
    display:flex;
    flex-direction:column;
    flex-wrap:wrap;
    width:100%;
    margin-top:2rem;
    .delivery-item {
      width:100%;
      margin:0 0 1.5rem 0;
      padding:0 1.5rem;
      text-align:center;
      h3 {
        margin-bottom:0;
        color:$primary-color;
      }
      p {
        @include font_P1();
        padding:0 .75rem;
      }
      .icon {
        width:85px;
        height:85px;
        border:2px solid $primary-color;
        border-radius:50%;
        margin:0 auto 1rem auto;
        display:flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
@media (min-width: 768px) {
  .delivery-section {
    .delivery {
      flex-direction:row;
      .delivery-item {
        width:50%;
        margin:0 0 2rem 0;
      }
    }
  }
}
@media (min-width: 992px) {
  .delivery-section {
    .delivery {
      .delivery-item {
        width:33.33333%;
        margin:0 0 3rem 0;
      }
    }
  }
}
