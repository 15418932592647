.cols {
  width:100%;
  position:relative;
  display:flex;
  flex-direction: column;
  flex-wrap:wrap;
  .col-half {
    width:100%;
    &.text-block {
      padding:15px;
      p, ul, ol, li, table {
        @include font_P1();
      }
    }
    &.gradient {
      h1, h2, h3, h4, h5, h6, p, ul, ol, li, table {
        color:white;
      }
      h1, h2, h3, h4, h5, h6 {
        &:after {
          background:white;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .cols {
    flex-direction: row;
    .col-half {
      width:50%;
      &.text-block {
        padding:50px;
        display: flex;
        justify-content: center;
        align-items: center;
        div {
          max-width:420px;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .cols {
    .col-half {
      &.text-block {
        padding:65px;
        div {
          max-width:620px;
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .cols {
    .col-half {
      &.text-block {
        div {
          max-width:720px;
        }
      }
    }
  }
}