#home-spotlight {
  width:100%;
  min-height:300px;
  height:300px;
  padding: 0;
  position:relative;
  .swiper-container {
    width: 100%;
    height: 100%;
    .swiper-wrapper {
      width:100%;
      height:100%;
      .swiper-slide {
        width:100%;
        height:100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
      }
      .image {
        width:100%;
        height:100%;
        position:relative;
        display:flex;
        justify-content: center;
        align-items: center;
        background-repeat:no-repeat;
        background-size:cover;
        background-position: center center;
        z-index:0;
      }
      .house-info {
        position:absolute;
        bottom:2rem;
        right:2rem;
        left:2rem;
        margin:0 auto;
        padding:.5rem 2rem .5rem 1rem;
        border-radius:5px;
        z-index:10;
        .address,
        .city {
          width:100%;
          position:relative;
          text-align:left;
          text-transform:uppercase;
          line-height:1.1;
          color:white;
          display:inline-block;
          margin:0 auto;
        }
        .address {
          font-weight:700;
          font-size:30px;
        }
        .city {
          font-weight:300;
          font-size:18px;
          margin-top:5px;
        }
        .btn-circle {
          position:absolute;
          right:1rem;
          top:50%;
          border:2px solid $primary-color;
          transform:translateY(-50%);
        }
        .house-details {
          position:relative;
          display:flex;
          flex-direction: row;
          flex-wrap:wrap;
          align-items: center;
          justify-content: space-between;
          background:rgba(85,84,80,.9);
          padding:3px;
          margin:20px auto 0 auto;
          height:50px;
          min-width:500px;
          max-width:525px;
          border-radius:25px;
          z-index:10;
          ul {
            margin:0;
            padding:0 30px;
            list-style:none;
            display:flex;
            flex-direction: row;
            flex-wrap:wrap;
            li {
              position:relative;
              color:$tertiary-color;
              margin:0 2rem 0 0;
              font-size:18px;
              .icon {
                margin:0 5px 0 0;
                max-height:35px;
                max-width:30px;
                position:relative;
              }
              &:last-child {
                margin:0;
              }
            }
          }
        }
      }
    }
    .swiper-pagination {
      .swiper-pagination-bullet {
        background:$tertiary-color;
        width:32px;
        height:3px;
        border-radius:3px;
        opacity:1;
        &.swiper-pagination-bullet-active {
          background:$primary-color;
        }
      }
    }
  }
  .brand {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    margin:0 auto;
    width:350px;
    height:156px;
    z-index:100;
    img {
      max-width:100%;
      display:block;
      position:relative;
      z-index:100;
    }
    &:after {
      content:"";
      position:absolute;
      top:50%;
      left:50%;
      transform:translate(-50%,-50%);
      width:130%;
      height:130%;
      background:rgba(255,255,255,.85);
      opacity:1;
      border:1px solid $primary-color;
      border-radius:5px;
      z-index:0;
    }
  }
}
@media (min-width: 992px) {
  #home-spotlight {
    min-height: 350px;
    height:100%;
  }
}
@media (min-width: 1200px) {
  #home-spotlight {
    min-height: 600px;
  }
}