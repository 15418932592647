#contact-info {
  .col-half {
    h2 {
      @include font_H1();
      &:after {
        background:white;
      }
    }
    p, a {
      color:white;
      font-size:20px;
    }
    a {
      transition: color ease-in-out .3s;
      i {
        margin-right:4px;
      }
      &:hover,
      &:focus {
        text-decoration: none;
        color:$primary-color-light;
      }
    }
    .image {
      background-position:center center;
      width:100%;
      height:325px;
      overflow:hidden;
      position:relative;
      z-index:0;
    }
  }
}

#socials-banner {
  text-align:center;
  h3 {
    @include font_H2();
    &:after {
      left:0;
      right:0;
      margin:0 auto;
    }
  }
  ul.socials {
    list-style:none;
    margin:.4rem 0 0 0;
    padding:0;
    li {
      font-size:40px;
      height:40px;
      line-height:1;
      margin:0 8px 0 0;
      float: left;
      position: relative;
    }
  }
}

#maps {
  width:100%;
  height:500px;
  overflow:hidden;
}

@media (min-width: 992px) {
  #contact-info {
    .col-half {
      .image {
        min-height:450px;
        height: 100%;
      }
    }
  }
}
@media (min-width: 1200px) {
  #contact-info {
    .col-half {
      .image {
        min-height:600px;
      }
    }
  }
}