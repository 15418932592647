.projects {
  background:$tertiary-color;
}

.project-item {
  background:white;
  border-radius:5px;
  box-shadow:0 16px 16px 0 rgba(0,0,0,.03);
  position:relative;
  margin-bottom:4rem;
  display:block;
  text-decoration:none;
  cursor:pointer;
  span.label {
    position:absolute;
    top:-15px;
    left:30px;
    display:block;
    padding:.25rem .75rem;
    background:$primary-color;
    color:white;
    border-radius:3px;
    text-transform:uppercase;
    font-size:14px;
    z-index:5;
  }
  span.image {
    position:relative;
    display:block;
    overflow:hidden;
    z-index:0;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    img {
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
    }
    .icon {
      position:absolute;
      top:50%;
      left:50%;
      transform:translate(-50%,-50%);
      background:white;
      width:0;
      height:0;
      border-radius:50%;
      z-index:6;
      transition:width ease-in-out .2s, height ease-in-out .2s;
      i {
        position:absolute;
        top:50%;
        left:50%;
        font-size:0;
        transform:translate(-50%,-50%);
        color:$primary-color;
        z-index:7;
        transition:font-size ease-in-out .2s;
      }
    }
    &:after {
      content:"";
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      background:rgba(0,0,0,0);
      transition:background ease-in-out .2s;
    }
  }
  span.details {
    position:relative;
    display:block;
    padding:1rem;
    z-index:1;
    h2 {
      font-size:21px;
      text-decoration: none;
      font-weight:700;
      margin:0;
      padding:0;
      &:after {
        content:"";
        width:0;
        height:0;
      }
    }
    h3 {
      margin:0;
      padding:0;
      font-weight:400;
      font-size:16px;
      color:$secondary-color;
    }
    h4.pricing {
      display:block;
      position:relative;
      width:100%;
      font-size:21px;
      font-weight:700;
      color:$primary-color;
      border-top:1px solid lighten($tertiary-color,20%);
      margin:1rem 0 0 0;
      padding:1rem 1rem 0 0;
    }
    .list-icons {
      margin:1.5rem 0 0 0;
      padding:0;
      list-style:none;
      display:flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      li {
        margin-right:30px;
        text-align:left;
        color:#9ea7a1;
        img {
          margin-right:2px;
        }
      }
    }
  }
  &:hover,
  &:focus {
    text-decoration:none;
    span.image {
      .icon {
        width:47px;
        height:47px;
        i {
          font-size:18px;
        }
      }
      &:after {
        background:rgba(0,0,0,.2);
      }
    }
    span.details {
      h2, h3 {
        text-decoration: none;
      }
    }
  }
}