@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700&display=swap');

@import "partials/_variables";
@import "partials/_mixins";
@import "partials/_alerts";
@import "partials/_global";
@import "partials/_animations";
@import "partials/_buttons";
@import "partials/_nav";
@import "partials/_bar";
@import "partials/_footer";
@import "partials/_header";
@import "partials/_cols";

@import "partials/_forms";

@import "layouts/home/_slider";
@import "layouts/home/_buttons";
@import "layouts/home/_intro";

@import "layouts/team/_employees";
@import "layouts/team/_vacancies";

@import "layouts/project/_index";
@import "layouts/project/_show";

@import "layouts/contact/_index";

@import "layouts/delivery/_show";


