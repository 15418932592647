::-moz-selection {
  background: $primary-color;
  color: white;
}

::selection {
  background: $primary-color;
  color: white;
}

html, body {
  height: 100%;
  padding: 0;
  margin: 0;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  height: 100% !important;
  position: relative;
  min-height: 100%;
  overflow-x: hidden;
  font-family: $primary-font-family;
  color: $secondary-color;
  padding: 89px 0 0 0;
  margin: 0;
  background: white;
  z-index:0;
}

a {
  color:$primary-color;
  &:hover,
  &:focus {
    color:darken($primary-color,8%);
  }
}

h1 {
  @include font_H1();
}
h2 {
  @include font_H2();
}

.bg-primary {
  background:$primary-color !important;
  color:white;
  h2 {
    &:after {
      background:white;
    }
  }
  a {
    color:white !important;
    text-decoration:underline;
  }
}

section {
  width:100%;
  position:relative;
  &.taupe {
    background:$secondary-color;
  }
  &.py-lg {
    padding:30px 0;
  }
  &.pt-lg {
    padding:30px 0 0 0;
  }
  &.pb-lg {
    padding:0 0 30px 0;
  }
  .trigger {
    position:absolute;
    width:100%;
    height:1px;
    top:0;
    left:0;
  }
  .gradient {
    @include gradient();
  }
}

.text-lg {
  p, ul, ol, li, table, tr, td {
    font-size:21px;
    line-height:32px;
  }
  p, table {
    margin:0 0 2rem 0;
  }
  margin-bottom:40px;
}
.text-block {
  p, ul, ol, li, table, tr, td {
    @include font_P1();
  }
  p, table {
    margin:0 0 2rem 0;
  }
  ul, ol {
    padding:0 0 0 1rem;
    margin:0 0 2rem 0;
  }
  blockquote {
    background:$primary-color;
    color:white;
    padding:2rem 2rem 1rem 2rem;
    position:relative;
    border-radius:5px;
    h3 {
      font-weight:400;
      color:white;
      position:relative;
      z-index:10;
    }
    p {
      color:white;
      font-style:italic;
      position:relative;
      z-index:10;
    }
    &:before {
      content:"\f10e";
      font-family: "Font Awesome 5 Free";
      font-size:58px;
      font-weight:700;
      color:lighten($primary-color, 10%);
      position:absolute;
      top:1rem;
      right:1rem;
      transform:translate(-50%,-50%);
      opacity:1;
      z-index:0;
    }
  }
}

@media (min-width: 992px) {
  body {
    padding:0;
  }
  section {
    &.py-lg {
      padding:50px 0;
    }
  }
}
@media (min-width: 1200px) {
  section {
    &.py-lg {
      padding:75px 0 90px 0;
    }
  }
}

@media (min-width: 1550px) {
  .container {
    max-width:1440px;
  }
}