.project-header {
  position:relative;
  margin-top:30px;
  margin-bottom:30px;
}
.project-header-container {
  width:100%;
  display:flex;
  flex-direction: row;
  flex-wrap:wrap;
  justify-content: space-between;
  align-items: center;

  h1 {
    @include font_H1();
    font-size:36px !important;
    font-weight:700;
    line-height:42px;
    color:$primary-color;
    margin:0;
    padding:0;
    &:after {
      width:0;
      height:0;
    }
  }
  h2 {
    @include font_H3();
    margin-top:.15rem;
    &:after {
      width:0;
      height:0;
    }
  }
  .pricing {
    @include font_H3();
    color:$primary-color;
  }
}

.wrapper-project-info {
  position:relative;
  width:100%;
  .container {
    position:relative;
    display:flex;
    flex-wrap:wrap;
    flex-direction:column;
  }
  .features {
    display:block;
    width:100%;
    position:relative;
    margin:2rem 0 0 0;
    padding:1rem 0;
    border-top:1px solid lighten($tertiary-color, 10%);
    border-bottom:1px solid lighten($tertiary-color, 10%);
    ul {
      position:relative;
      display:flex;
      flex-direction: row;
      flex-wrap:wrap;
      justify-content: space-between;
      margin:0;
      padding:0;
      list-style:none;
      li {
        display:block;
        text-align:center;
        padding:1rem .5rem;
        span {
          @include font_H3();
          font-size:16px !important;
          font-weight:700;
          color:#9ea7a1;
          display:none;
        }
        span + span {
          font-weight:300;
          display:block;
        }
        img,
        span {
          margin:0 auto;
          text-align:center;
        }
        img {
          display:block;
        }
      }
    }
  }
  .description {
    position:relative;
    margin-top:2rem;
    p, ul, ol, li {
      @include font_P1();
    }
    p {
      margin:0 0 2rem 0;
    }
    ul, ol {
      margin:0 0 2rem 0;
      padding:0 0 0 1rem;
    }
  }

  .floor-map {
    .mapping-list {
      margin:2rem 0 0 0;
      padding:0;
      list-style:none;
      display:flex;
      flex-direction: row;
      flex-wrap:wrap;
      li {
        width:33.333333%;
        padding-bottom:2rem;
        padding-right:1rem;
        a {
          display:block;
          width:100%;
          margin:0;
          border:1px solid $primary-color;
          border-radius:5px;
          overflow:hidden;
          position:relative;
          &:after {
            content:"";
            background:rgba(0,0,0,0);
            position:absolute;
            width:100%;
            height:100%;
            top:0;
            left:0;
            z-index:10;
            transition:background ease-in-out .3s;
          }
          &:hover,
          &:focus {
            &:after {
              background:rgba(0,0,0,0.5);
            }
          }
        }
      }
    }
  }

  section {
    flex: 0 0 100%;
    max-width: 100%;
  }
  aside {
    position:relative;
    padding:0 0 30px 0;
    flex: 0 0 100%;
    max-width: 100%;
    .card-aside {
      position:relative;
      background:#fbfbfb;
      border-radius:5px;
      h3 {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        color:white;
        padding:1rem 2rem;
        background:darken($tertiary-color, 10%);
      }
      .estate-agent-details {
        @include font_P1();
        padding:1rem 2rem;
        color:$secondary-color;
        ul {
          list-style:none;
          margin:0;
          padding:0;
          li {
            a {
              &:hover,
              &:focus {
                text-decoration:none;
              }
            }
          }
        }
      }
    }
    .funda {
      background:white;
      a {
        display:block;
        cursor:pointer;
        position:relative;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        overflow:hidden;
        img {
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
      h3 {
        @include font_H3();
        margin:0;
      }
    }
    .card-aside + * {
      margin-top:2rem;
    }
  }
}
.project-information{
  .container {
    position:relative;
  }
  .swiper-container {
    .swiper-wrapper {
      border-radius:5px;
      overflow:hidden;
      position:relative;
      z-index:0;
    }
    .swiper-button-next,
    .swiper-button-prev {
      width:50px;
      height:50px;
      border-radius:50%;
      background:rgba(255,255,255,.5);
      transition:background ease-in-out .2s;
      i {
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        color:$primary-color;
        opacity:.8;
        transition:opacity ease-in-out .2s;
      }
      &:hover,
      &:focus {
        background:rgba(255,255,255,.75);
        i {
          opacity:1;
        }
      }
    }

  }
  span.label-primary {
    position:absolute;
    top:-15px;
    left:45px;
    z-index:100;
    background:$primary-color;
    color:white;
    border-radius:5px;
    font-size:18px;
    text-transform:uppercase;
    padding:.25rem 1rem;
  }
}
.project-header-details {
  width:33.3333333%;
  padding:0 2rem;

  h3 {
    border:2px solid lighten($tertiary-color, 20%);
    @include font_H3();
    font-weight:300;
    padding:1rem;
    span {
      font-weight:700;
    }
  }
  .list-highlight {
    list-style: none;
    margin:2rem 0 0 0;
    padding:0;
    li {
      padding:1rem 0;
      font-family: $primary-font-family;
      font-size:20px;
      font-weight:300;
      img {
        margin-right:5px;
      }
      span {
        font-weight:700;
      }
    }
  }
}
@media (min-width: 992px) {
  .project-header {
    margin-top:140px;
  }

  .wrapper-project-info {
    .container {
      flex-direction:row;
    }
    section {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    aside {
      min-height:667px;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
      height:100%;
      padding:0 0 0 30px;
      &.sticky {
        position:sticky;
        top:130px;
      }
    }

    .features {
      ul {
        flex-wrap:nowrap;
        li {
          padding:0 1rem;
          span {
            display:block;
            font-size:20px !important;
            font-weight:700;
          }
        }
      }
    }
  }
}