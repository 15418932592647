#team {
  ul {
    margin:0;
    padding:0;
    list-style:none;
    position:relative;
    display:flex;
    flex-wrap:wrap;
    flex-direction:column;
    li {
      width:100%;
      position:relative;
      display:flex;
      flex-wrap:wrap;
      flex-direction:column;
      .image {
        width:100%;
        display:block;
        img {
          display:block;
          max-width:100%;
        }
      }
      .profile {
        width:100%;
        background:$tertiary-color;
        display:flex;
        align-items: center;
        justify-content: center;
        text-align:center;
        flex-direction:column;
        padding:3rem 3rem;
        flex:1;
        position:relative;
        z-index:5;
        h3 {
          @include font_H3();
          color:$primary-color;
          margin:0;
          padding:0;
          a {
            i {
              margin-right:5px;
              font-size:18px;
            }
            &:hover,
            &:focus {
              text-decoration: none;
            }
          }
        }
        h4 {
          @include font_H4();
          margin:0 0 2rem 0;
          padding:0;
        }
        .motto {
          @include font_H3();
          position:relative;
          z-index:2;
          display:block;
          &:before {
            content:"\f10e";
            font-family: "Font Awesome 5 Free";
            font-weight:700;
            color:darken($tertiary-color, 30%);
            display:block;
            position:absolute;
            font-size:90px;
            top:-40px;
            left:0;
            right:0;
            margin:0 auto;
            opacity:.1;
            z-index:0;
          }
        }
        .more-info {
          margin-top:2rem;
          display:block;
          position:relative;
          z-index:5;
          .toggle {
            color:$primary-color;
            font-size:18px;
            text-transform:uppercase;
            cursor:pointer;
            i {
              margin-left:3px;
            }
            &:hover,
            &:focus {
              color:$secondary-color;
            }
          }
        }
      }
      .close {
        position:absolute;
        top:1rem;
        right:1rem;
      }
      .extra-info {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        padding:0;
        overflow:auto;
        background:$primary-color;
        z-index:100;
        .inner {
          @include font_P1();
          color:$primary-color-light;
          display:block;
          padding:3rem 1.5rem;
        }
        a {
          color:$primary-color-light;
          transition:color ease-in-out .3s;
          i {
            opacity:.6;
          }
        }
        &.inactive {
          height:0;
          -webkit-animation-duration: .5s;
          animation-duration: .5s;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
          -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
        }
        &.active {
          height:100%;
          -webkit-animation-duration: .5s;
          animation-duration: .5s;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
          -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
        }
      }
      &:nth-child(4n+1),
      &:nth-child(4n+4){
        .profile {
          background:lighten($tertiary-color, 20%);
          .motto {
            &:before {
              color:darken($tertiary-color, 25%);
            }
          }
        }
      }
    }
  }
}
@media (min-width: 992px) {
  #team {
    ul {
      flex-direction:row;
      li {
        width:50%;
        flex-direction:row;
        .image {
          width: 42%;
        }
        .profile {
          width:58%;
          padding:0 3rem;
        }
        .extra-info {
          .inner {
            padding:3rem;
          }
        }
      }
    }
  }
}