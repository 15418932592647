.btn-circle {
  width:44px;
  height:44px;
  display:block;
  border-radius:23px;
  position:relative;
  i {
    position:absolute;
    font-size:22px;
    left:50%;
    top:50%;
    transform:translate(-50%,-50%);
  }
  &.color {
    background: $primary-color-light;
  }
  &.gradient {
    @include gradient();
    &:hover,
    &:focus {
    }
  }
}

.btn-readmore {
  font-family: $primary-font-family;
  font-size:18px;
  font-weight:300;
  line-height:18px;
  letter-spacing:1px;
  height:46px;
  display:flex;
  padding:10px 50px 10px 24px;
  color:white;
  position:relative;
  background:rgba(166,92,55,.5);
  border:2px solid $primary-color;
  border-radius:30px;
  transition:background ease-in-out .3s;
  &:before {
    content:"";
    position:absolute;
    @include gradient();
    width:36px;
    height:36px;
    border-radius:18px;
    right:2px;
    top:3px;
    z-index:1;
  }
  &:after {
    content:"\f054";
    font-family: "Font Awesome 5 Free";
    font-size:19px;
    font-weight:700;
    color:$primary-color;
    position:absolute;
    right:12px;
    top:12px;
    z-index:2;
  }
  &:hover,
  &:focus {
    text-decoration:none;
    color:white;
    background:rgba(166,92,55,.8);
  }
}

.btn-card {
  display:block;
  position:relative;
  overflow:hidden;
  width:100%;
  .btn-readmore {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    min-width:200px;
    z-index:5;
  }
  .card-image {
    position:relative;
    width:100%;
    display:block;
    z-index:1;
  }
}

.back {
  position:fixed;
  bottom:50px;
  left:30px;
  z-index:9999;
}

@media (min-width: 992px) {
  .btn-circle {
    transition: color ease-in-out .4s;
  }
  .btn-card {
    cursor:pointer;
    .btn-readmore {
      min-width:auto;
    }
    .card-image {
      width: 100%;
      height: 100%;
      display: block;
      overflow:hidden;
      position:relative;
    }
    &:before {
      content:"";
      display:block;
      position:absolute;
      top:10px;
      left:10px;
      width:calc(100% - 20px);
      height:calc(100% - 20px);
      background:transparent;
      border:1px solid $primary-color-light;
      z-index:4;
    }
    &:after {
      content:"";
      @include gradient_black_bottom();
      position:absolute;
      bottom:0;
      left:0;
      right:0;
      width:100%;
      height:50%;
      opacity:.4;
      transition:height ease-in-out .3s, opacity ease-in-out .3s;
      z-index:3;
    }
    &:hover,
    &:focus {
      .card-image {
        img {
          transition: transform 5s linear;
          transform: rotate(-1deg) scale(1.2);
        }
      }
      &:after {
        opacity:.7;
        height:90%;
      }
    }
  }
}