.bar {
  position:fixed;
  top:8px;
  right:1rem;
  z-index:1000;
  .bar-items {
    display:none;
    flex-direction: row;
    flex-wrap:wrap;
    justify-content: center;
    align-items: center;
    font-size:15px;
  }
  ul {
    list-style:none;
    margin:0 22px 0 0;
    padding:0;
    li {
      float:left;
      position:relative;
      color:darken($secondary-color, 10%);
      a {
        color:darken($secondary-color, 10%);
        &:hover,
        &:focus {
          text-decoration: none;
          color:$primary-color-light;
        }
      }
    }
    &.contact-info {
      li {
        margin:0 30px 0 0;
        text-transform:uppercase;
        a {
          i {
            margin-right:3px;
          }
        }
        &:last-child {
          margin:0;
        }
      }
    }
    &.socials,
    &.languages {
      li {
        margin:0 7px 0 0;
        &:last-child {
          margin:0;
        }
      }
    }
    &.languages {
      margin-right:40px;
    }
  }
  &.smaller {
    .bar-items {
      ul {
        li {
          a {
            color:$primary-color-light;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .bar {
    .bar-items {
      display:flex;
    }
  }
}
@media (min-width: 1200px) {
  .bar {
    ul {
      margin:0 36px 0 0;
    }
  }
}