.fixed-top {
  top:0;
  background:white;
  z-index:999;
  padding:1rem 1rem;
  box-shadow:0 0 10px rgba(0,0,0,0.15);
  .logo {
    margin-top:-3px;
    height:60px !important;
    display:block;
    img {
      transition:all ease-in-out .3s;
      max-width:100%;
      height:60px !important;
      width:135px !important;
    }
  }
  .container {
    position:relative;
    justify-content: flex-end;
  }
  button.navbar-toggler {
    background: transparent;
    border: 0;
    float: right;
    width: 44px;
    height: 24px;
    position: relative;
    margin: 0;
    padding:0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    span {
      display: block;
      position: absolute;
      height: 4px;
      border-radius: 2px;
      width: 100%;
      background: $primary-color;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
    }
    span:nth-child(1) {
      top: 0;
    }

    span:nth-child(2) {
      top: 10px;
      width:32px;
    }

    span:nth-child(3) {
      top: 20px;
      width:40px;
    }
  }
  .navbar-toggler.open span:nth-child(1) {
    top: 12px;
    width:32px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  .navbar-toggler.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }

  .navbar-toggler.open span:nth-child(3) {
    top: 12px;
    width:32px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
  .navbar-nav {
    margin-top:45px;
    li {
      a {
        color:$secondary-color;
        font-size:20px;
        padding:.5rem .75rem;
        border-radius:0;
        border-bottom:1px solid lighten($tertiary-color,20%);
        display:block;
        text-align:center;
        text-transform:lowercase;
        &:hover,
        &:focus {
          color:$primary-color;
          text-decoration:none;
        }
      }
      &.dropdown {
        .dropdown-menu {
          padding:0;
          background:#f5f5f5;
          border:0;
          border-radius:0;
          li {
            a {
              color:$secondary-color;
              &:hover,
              &:focus {
                color:$primary-color;
              }
            }
            &.active {
              a {
                color:$primary-color;
              }
            }
          }
        }
      }
      &.active {
        a {
          color:$primary-color;
        }
      }
      &:last-child {
        a {
          border-bottom:0;
        }
      }
    }
  }
  &.smaller {
    .navbar-nav {
      li {
        a {
          color:$primary-color;
        }
      }
    }
  }
}
@media (min-width: 992px) {
  .fixed-top {
    background:transparent;
    padding:.75rem 1rem;
    display:block;
    border:0;
    box-shadow:0 0 0;
    transition:background ease-in-out .35s;
    .logo {
      margin-top:-3px;
      height:65px !important;
      img {
        transition:all ease-in-out .3s;
        max-width:100%;
        height:65px !important;
        width:148px !important;
      }
    }
    .container {
      justify-content: space-between;
    }
    .navbar-nav {
      margin-top:1rem;
      li {
        margin:.2rem 0;
        padding:0;
        a {
          outline:0;
          position:relative;
          font-size:16px;
          color:$secondary-color;
          padding:.25rem .5rem .25rem .5rem;
          text-transform:uppercase;
          border-radius:25px;
          border-bottom:0;
          text-decoration: none;
          transition:background ease-in-out 300ms, color ease-in-out 300ms;
          &:hover,
          &:focus {
            outline:0;
            color:$primary-color;
          }
        }
        &.dropdown {
          .dropdown-toggle {
            &:after {
              border-top: .25em solid;
              border-right: .25em solid transparent;
              border-bottom: 0;
              border-left: .25em solid transparent;
            }
          }
          .dropdown-menu {
            background:white;
            margin:0;
            padding:1.5rem 1rem;
            box-shadow:0 0 10px rgba(0,0,0,0.15);
            border:0;
            min-width:22rem;
            li {
              a {
                position:relative;
                text-align:left;
                font-size:18px;
                padding:.5rem 1rem;
                color:$secondary-color;
                text-transform:inherit;
                border-bottom:1px solid lighten($tertiary-color, 20%);
                border-radius:0;
                &:after {
                  content:"\f054";
                  font-family: "Font Awesome 5 Free";
                  font-weight:700;
                  position:absolute;
                  font-size:14px;
                  right:0;
                  top:12px;
                }
                &:hover,
                &:focus {
                  color:$primary-color;
                }
              }
              &.active {
                a {
                  background:transparent;
                  border:0;
                  border-bottom:1px solid lighten($tertiary-color, 20%);
                  color:$primary-color;
                }
              }
              &:last-child {
                a {
                  border-bottom:0;
                }
              }
            }
          }

          &:hover {
            .dropdown-menu {
              display:block;
            }
          }
        }
        &.active {
          a {
            color:$primary-color;
          }
        }
      }
    }
    &.smaller {
      background: rgba(85,84,80,.98);
      border-bottom:1px solid rgba(255,255,255,.1);
      .logo {
        height:65px !important;
        img {
          max-height:65px;
          width:148px !important;
          height:65px !important;
        }
      }
      .navbar-nav {
        li {
          a {
            color:$tertiary-color;
            &:hover,
            &:focus {
              color:$primary-color-light;
            }
          }
          &.active {
            a {
              color:$primary-color-light;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1200px) {
  .fixed-top {
    padding:1.925rem 1rem;
    transition:all ease-in-out .4s;
    .logo {
      height:80px !important;
      img {
        max-height:80px;
        height:80px !important;
        width:180px !important;
      }
    }
    .navbar-nav {
      margin-top:0;
      li {
        margin:0 .25rem;
        a {
          font-size:18px;
          padding:.25rem .75rem .25rem .75rem;
        }
      }
    }
    &.smaller {
      padding:1.25rem 1rem;
      .navbar-nav {
        margin-top:1rem;
      }
    }
  }
}

@media (min-width: 1600px) {
  .fixed-top {
    .logo {
      height:115px !important;
      img {
        max-height:115px;
        height:115px !important;
        width:260px !important;
      }
    }
    .navbar-nav {
      li {
        margin:0 .5rem;
        a {
          font-size:20px;
        }
      }
    }
  }
}