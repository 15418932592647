.form-group {
  margin-bottom:.5rem;
  input[type=text],
  input[type=tel],
  input[type=email]{
    &.form-control {
      -webkit-box-shadow:inset 0 0 4px rgba(0,0,0,.08);
      -moz-box-shadow:inset 0 0 4px rgba(0,0,0,.08);
      box-shadow:inset 0 0 4px rgba(0,0,0,.08);
    }
    &.form-control {
      @include font_P1();
      height:48px;
      &:focus {
        border-color: $primary-color;
      }
    }
  }
  textarea {
    &.form-control {
      @include font_P1();
      resize:none;
      min-height:180px;
      -webkit-box-shadow:inset 0 0 4px rgba(0,0,0,.08);
      -moz-box-shadow:inset 0 0 4px rgba(0,0,0,.08);
      box-shadow:inset 0 0 4px rgba(0,0,0,.08);
      &:focus {
        border-color: $primary-color;
      }
    }
  }
  &.form-group-lg {
    margin-bottom:1.5rem;
    padding:0 1rem 1rem 1rem;
    border-radius:5px;
    background:lighten($tertiary-color, 15%);
    label {
      font-weight:400;
    }
  }
}

.form-group.has-error {
  .form-control {
    border-color:$red;
  }
}

.alert {
  @include font_P1();
}

.alert-danger {
  background:$red;
  color:white;
  margin-bottom:30px;
  ul {
    margin:0;
    padding:0;
    list-style:none;
    border-radius:0;
    li {
      color:white !important;
      padding-left:15px;
      position:relative;
      &:before {
        content:"\f12a";
        font-family: "Font Awesome 5 Free";
        font-weight:700;
        font-size:15px;
        background:none;
        position:absolute;
        left:0;
        top:0;
        color:white;
      }
    }
  }
}

.contactform {
  h3 {
    @include font_H2();
  }
}

.form-section {
  background:$tertiary-color;
  h3 {
    font-weight:700;
    color:$primary-color;
    margin:0 0 1rem 0;
  }
  .row + h3 {
    margin-top:2rem;
  }

  label {
    @include font_P1();
    line-height:48px;
    width:100%;
  }

  .form-check {
    label {
      line-height:1;
    }
  }

  .btn-readmore {
    position:relative;
  }
  .bootstrap-tagsinput {
    height:48px;
    width:100%;
    padding: .45rem .75rem;
    font-size: 1rem;
    line-height: 1.9;
    color:$secondary-color;
    .tag {
      background:$primary-color;
      border-radius:3px;
      padding:.25rem 1.7rem .25rem .45rem;
      position:relative;
      span {
        border-radius:50%;
        background:$primary-color-light;
        width:17px;
        height:17px;
        display:block;
        right:5px;
        top:50%;
        transform:translateY(-50%);
        position:absolute;
        &:after {
          position:absolute;
          top:48%;
          left:50%;
          transform:translate(-48%,-50%);
          padding:0 !important;
          line-height:1;
          color:$primary-color;
        }
      }
    }
  }

  .irs--round .irs-from,
  .irs--round .irs-to,
  .irs--round .irs-single {
    background:$primary-color;
    &:before {
      border-top-color:$primary-color;
    }
  }
  .irs--round .irs-bar {
    background:$primary-color;
  }
  .irs--round .irs-handle {
    border-color:$primary-color;
    box-shadow:0 1px 3px rgba(255,255,255,0.1);
  }
  .irs--round .irs-line {
    background:$primary-color-light;
  }
  .irs--round .irs-grid-text {
    color:$tertiary-color;
  }
}
@media (min-width: 992px) {
  .form-section {
    .form-col {
      padding-right:30px;
    }
    .form-col + .form-col {
      padding-right:0;
      padding-left:30px;
    }
    .col-small {
      padding-right:.25rem;
    }
    .col-small + .col-medium {
      padding-left:.25rem;
    }
  }
  .form-group {
    &.form-group-lg {
      padding:.5rem 2rem 2rem 1rem;
      label {
        margin-bottom:1rem;
      }
    }
  }
}

