@mixin gradient {
  background: rgb(166,92,55);
  background: linear-gradient(90deg, rgba(166,92,55,1) 0%, rgba(237,190,149,1) 50%, rgba(166,92,55,1) 100%);
}

@mixin gradient_black_bottom {
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
}

@mixin gradient_black_top {
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
}

@mixin font_H1 {
  font-family: $primary-font-family;
  font-size:32px;
  font-weight:400;
  line-height:1.25;
  position:relative;
  margin:0 0 15px 0;
  padding:0 0 15px 0;
  &:after {
    content:"";
    background: $primary-color;
    position:absolute;
    width:75px;
    height:2px;
    bottom:0;
    left:0;
    border-radius:2px;
  }
  @media (min-width: 992px) {
    font-size:36px;
  }
  @media (min-width: 1200px) {
    font-size:40px;
  }
}

@mixin font_H2 {
  font-family: $primary-font-family;
  font-size:24px;
  font-weight:300;
  line-height:1.5;
  position:relative;
  margin:0 0 15px 0;
  padding:0 0 15px 0;
  &:after {
    content:"";
    background: $primary-color;
    position:absolute;
    width:75px;
    height:2px;
    bottom:0;
    left:0;
    border-radius:2px;
  }
  @media (min-width: 992px) {
    font-size:24px;
  }
  @media (min-width: 1200px) {
    font-size:28px;
  }
}

@mixin font_H3 {
  font-family: $primary-font-family;
  font-size:21px;
  font-weight:300;
  line-height:1.5;
  position:relative;
  @media (min-width: 992px) {
    font-size:22px;
  }
  @media (min-width: 1200px) {
    font-size:24px;
  }
}

@mixin font_H4 {
  font-family: $primary-font-family;
  font-size:20px;
  font-weight:700;
  position:relative;
  margin:0;
  padding:0;
  @media (min-width: 992px) {
    font-size:17px;
  }
}

@mixin font_P1 {
  font-family: $primary-font-family;
  font-size:17px;
  font-weight:300;
  line-height:30px;
  letter-spacing:1px;
  @media (min-width: 992px) {
    font-size:17px;
    line-height:34px;
  }
}