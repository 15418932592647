#home-intro {
  .col-half {
    .image {
      background-position:center center;
      width:100%;
      height:100%;
      overflow:hidden;
      position:relative;
      z-index:0;
      img {
        position:relative;
        z-index:0;
      }
      .btn-readmore {
        position:absolute;
        right:3rem;
        bottom:3rem;
        z-index:2;
      }
    }
  }
}